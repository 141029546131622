<template>
  <v-container>
    
     <div>
       <v-row class="mb-4" justify="space-between" align-content="center">
        <h1 class="text text-h4 titulo">Bitacora de Producción</h1>
        <export-production-bitacora :filter="filter" :options="options" :disabled="loading"/>
       </v-row>
      <v-row class="mb-4" justify="end">
        <v-col align-self="center" cols="12" md="6">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_after' 
          class="mr-4" 
          outlined 
          clearable 
          type="date" 
          label="Creada Desde:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field 
          hide-details 
          :disabled="loading" 
          dense 
          v-model='filter.created_before' 
          clearable 
          outlined 
          type="date" 
          label="Creada Hasta:" 
          @change="()=>{options.page=1;fetch()}"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
            <v-select
              v-model="filter.machine"
              :items="machines"
              :loading="machinesLoading"
              item-text="name"
              item-value="id"
              return-object
              label="Area de Producción"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
              @change="()=>{options.page=1;filter.equipment=null;fetch()}"
            />
        </v-col>
        <v-col cols="12" md="6" align-self="center">
            <v-select
              v-model="filter.equipment"
              :items="equipments"
              item-text="name"
              item-value="id"
              label="Equipo"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
              :disabled="!filter.machine"
              @change="()=>{options.page=1;fetch()}"
            />
        </v-col>
        <v-col justify-end cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            @keyup="searchTimeOut"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        class="pa-4"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
         @update:options="fetch"
      >
        <template v-slot:top>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
        </template>
        <template v-slot:item.process_order__product__work_order__id="{item}">
          <show-work-order :work_order_id="item.work_order">
            <div class="caption">{{item.customer_name}}</div>
            {{item.work_order}}
          </show-work-order>
        </template>
        <template v-slot:item.process_order__product__name="{item}">
          <div class="caption">{{item.product_sku}}</div>
          <div class="caption">{{item.product_name}}</div>
        </template>
        <template v-slot:item.product_qty="{ item }">
          <div class="">{{item.product_qty |number('0,0')}} <v-icon small>view_comfy</v-icon></div>
          <div class="">{{item.product_mts2 |number('0,0')}}<v-icon small>square_foot</v-icon></div>
        </template>
        <template v-slot:item.process_order__name="{ item }">
           {{item.process_order_name}}
        </template>
        <template v-slot:item.process_order__machine__name="{ item }">
          {{item.machine_name}}
        </template>
        <template v-slot:item.equipment__name="{ item }">
          {{item.equipment_name}}
        </template>
        <template v-slot:item.production="{ item }">
          {{(item.machine_production_unit_m2?item.mts2:item.qty)|number('0,0')}} <v-icon small>{{item.machine_production_unit_m2?'square_foot':'view_comfy'}}</v-icon>
        </template>
        <template v-slot:item.created_by__first_name="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on">
                {{item.created_by}}
              </div>
            </template>
            {{item.notes}}
          </v-tooltip>
        </template>
  </v-data-table>
  </div>
  </v-container>
</template>

<script>
import {fetchBitacora,fetchMachines} from '@/api/production'
import ExportProductionBitacora from '@/components/production/ExportProductionBitacora'
import notifications from '@/utils/notifications'
import moment from 'moment'
export default {
  name:'ProductionBitacora',
  components:{ExportProductionBitacora},
  data() {
    return {
      loading:false,
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        machine:'',
        equipment:'',
        },
      options:{},
      totalItems:0,
      display:false,
      search:'',
      items:[],
      machines:[],
      machinesLoading:false,
      headers:[
          {text: 'Creada',align: 'start',value: 'created_at',class: 'table-header2',divider:true},
          {text: 'Cliente | OT',align: 'start',value: 'process_order__product__work_order__id',class: 'table-header2',divider:true,},
          //{text: 'Cot.',align: 'start',value: 'process_order__product__work_order__sales_note__id',class: 'table-header2'},
          //{text: 'Cliente',value:'process_order__product__work_order__sales_note__customer__name',class: 'table-header2'},
          {text: 'Producto',align: 'start',value: 'process_order__product__name',class: 'table-header2'},
          {text: 'Cant. Mts2',align: 'start',value: 'product_qty',class: 'table-header2',sortable:false,align:'end',divider:true,width:'8%'},
          //{text: 'Mts2.',align: 'start',value: 'product_mts2',class: 'table-header2',sortable:false,align:'end'},
          {text: 'Proceso',align: 'start',value: 'process_order__name',class: 'table-header2',width:'15%'},
          {text: 'Area Prod.',align: 'start',value: 'process_order__machine__name',class: 'table-header2',sortable:true,align:'start',width:'12%'},
          {text: 'Equip.',align: 'start',value: 'equipment__name',class: 'table-header2',sortable:true,align:'start',width:'12%'},
          {text: 'Producido',align: 'start',value: 'production',class: 'table-header2',sortable:false,align:'end'},
          {text:'Usuario',align:'start',value:'created_by__first_name',class:'table-header2',sortable:true,align:'end'},
          
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      
      if(!this.filter.machine) this.filter.machine={id:''}
      if(!this.filter.equipment) this.filter.equipment=''

      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${created_after}&created_before=${created_before}&process_order__machine__id=${this.filter.machine.id}&equipment__id=${this.filter.equipment}`
      await fetchBitacora(query)
      .then((data)=>{
        this.totalItems=data.count
        this.items = data.results
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  async mounted(){
    await this.fetch()
    this.machinesLoading=true
    this.machines=await fetchMachines('enabled=true&ordering=sort')
    this.machinesLoading=false
  },
  computed:{
      equipments(){
        return this.filter.machine?this.filter.machine.equipments:[]
      }
    }
}
</script>
