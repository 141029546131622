<template>
  <v-container>
     <div>
       <v-row class="mb-4" justify="space-between" align-content="center">
        <h1 class="text text-h4 titulo">Facturacion Ordenes de Trabajo</h1>
        <export-invoice-work-orders :filter="filter" :options="options" :disabled="loading"/>
       </v-row>
      <v-row class="mb-4" justify="end">
        <v-col align-self="center" cols="12" md="6">
          <v-text-field hide-details :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:"></v-text-field>
        </v-col>
        <v-col align-self="center">
          <v-text-field hide-details :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
          <Can I="readAll" a="SalesNote">
            <v-select
              v-model="filter.seller"
              :items="sellers"
              item-text="email"
              item-value="id"
              label="Vendedor"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
            />
          </Can>
        </v-col>
        <v-col cols="12" md="6" align-self="center">
            <v-select
              v-model="filter.has_invoiced"
              :items="has_invoiced_options"
              item-text="text"
              item-value="value"
              label="Estatus"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              hide-details
            />
        </v-col>
        <v-col justify-end cols="12" md="6">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            @keyup="searchTimeOut"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col >
          <number-card title="Total Confirmado">
              {{total_sales | currency('$', 0)}}
          </number-card>
        </v-col>
        <v-col>
          
          <number-card title="Costo Teórico [Margen]">
              {{total_estimated | currency('$', 0)}} [{{estimated_margin| percent}}]
          </number-card>
        </v-col>
        <v-col >
          <number-card title="Costo Real [Margen]">
              {{total_real | currency('$', 0)}} [{{real_margin | percent}}]
          </number-card>
        </v-col>
      </v-row> -->
      <v-data-table
        class="pa-4"
        :headers="headers"
        :items="workOrders"
        :options.sync="options"
        :server-items-length="totalWorkOrders"
        :search="search"
        sort-by="created_at"
        :sort-desc="false"
        :single-expand="true"
        :item-class="itemRowStyle"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
      >
        <template v-slot:top>
        <!-- <v-row justify="end">
          <v-col cols="6">
            <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="fas fa-search"
                @keyup="searchTimeOut"
                :disabled="loading"
              ></v-text-field>
          </v-col>
        </v-row> -->
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
        </template>
        
        <template v-slot:item.sales_note__name="{item}">
          {{item.name}}
        </template>
        <template v-slot:item.sales_note__customer__name="{item}">
          {{item.customer_name}}
        </template>
        <template v-slot:item.completed_at="{ item }">
          {{item.completed_at | moment("DD/MM/YYYY")}}
        </template>
        <template v-slot:item.total_invoiced_payed.invoiced="{ item }">
           {{item.total_invoiced_payed.invoiced | currency('$', 0)}}
        </template>
        <template v-slot:item.total_sale="{ item }">
          {{item.real_price.sales_total | currency('$', 0)}}
        </template>
  </v-data-table>
  </div>
  <v-dialog  v-model='display' max-width="1200">
    <v-card class="pa-4" >
      <work-order-card :work_order='workOrderOnDisplay' @close="display=false"/> 
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchPostCostWorkOrders,} from '@/api/reports'
import {fetchSellers} from '@/api/auth'
import CustomerComplete2 from '@/components/customers/CustomerComplete2.vue'
import WorkOrderCard from '@/components/production/WorkOrderCard'
import {pdfWorkOrder} from '@/doc_generators/workOrder'
import ExportInvoiceWorkOrders from '@/components/reports/ExportInvoiceWorkOrders'
import notifications from '@/utils/notifications'
import NumberLabel from '@/components/utils/NumberLabel'
import NumberCard from '@/components/utils/NumberCard'
import moment from 'moment'
export default {
  name:'InvoicedWorkOrders',
  components:{CustomerComplete2,WorkOrderCard,NumberLabel,ExportInvoiceWorkOrders,NumberCard},
  data() {
    return {
      loading:false,
      has_invoiced_options:[
        {text:'Facturado',value:'true'},
        {text:'No Facturado',value:'false'},
      ],
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        seller:'',
        has_invoiced:'true',
        },
      workOrderOnDisplay:{},
      options:{},
      totalWorkOrders:0,
      display:false,
      search:'',
      workOrders:[],
      estimated_margin:'',
      real_margin:'',
      total_sales:'',
      total_estimated:'',
      total_real:'',
      sellers:[],
      sellersLoading:false,
      headers:[
          {text: 'OT',align: 'start',value: 'id',class: 'table-header'},
          {text: 'Creada',align: 'start',value: 'created_at',class: 'table-header'},
          {text:'Finalizada',align:'start',value:'completed_at',class:'table-header'},
          {text: 'Proyecto',align: 'start',value: 'sales_note__name',class: 'table-header'},
          {text: 'Ct',value:'sales_note',class: 'table-header'},
          {text: 'Cliente',align: 'start',value: 'sales_note__customer__name',class: 'table-header'},
          {text: 'Venta',align: 'start',value: 'total_sale',class: 'table-header',sortable:false,align:'end'},
          {text: 'Neto Fact.',align: 'start',value: 'total_invoiced_payed.invoiced',class: 'table-header',sortable:false,align:'end'},
          
        ]
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
        //Arreglando variables de fecha en caso de ser null
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(new Date(this.filter.created_after)).format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(new Date(this.filter.created_before)).add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      
      if(!this.filter.seller) this.filter.seller=''
      if(!this.filter.has_invoiced) this.filter.has_invoiced=''
      
     
      

      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      if (!this.$can('readAll', 'SalesNote')){
          const user = this.$store.getters.getUser
          this.filter.seller=user.id
        }


      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&page_size=${itemsPerPage}&search=${this.search}&created_after=${created_after}&created_before=${created_before}&sales_note__customer__seller=${this.filter.seller}&has_invoices=${this.filter.has_invoiced}`
      await fetchPostCostWorkOrders(query)
      .then((data)=>{
        console.log(data)
        this.totalWorkOrders=data.count
        this.workOrders = data.results
        this.total_sales=data.total_sales
        
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    showWorkOrder(item){
      this.workOrderOnDisplay=item
      this.display=true
    },
    printWorkOrder(item){
      pdfWorkOrder(item,this.$store.getters.getUser)
    },
    update(item){
      this.$router.push({name:'WorkOrderUpdate',params:{workOrder:item}})
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    itemRowStyle(item){
      //return item.estimated==0 ? 'row-style-completed':'row-style-normal'
      
    }
  },
  async mounted(){
    await this.fetch()
    this.sellersLoading=true
    this.sellers=await fetchSellers()
    this.sellersLoading=false
  },
  watch:{
    options:{
      handler:'fetch',
      deep:true
    },
    filter:{
      deep: true,
      handler: 'fetch'
    },
  }
}
</script>

<style lang="scss">
  .row-style-completed{
    cursor: pointer;
  }
  .row-style-normal{
    cursor: pointer;
  }
</style>