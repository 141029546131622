<template>
<div>
  <v-btn color="secondary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="salesNotes"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>

      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchSalesNote} from '@/api/salesNotes'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportSalesNotes",
  props:['filter','options'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      salesNotes:[],
      dataReady:false,
      labels:{ 
        id:{title:'Nro'},
        created_at:{title:'Creada'},
        name: { title: 'Proyecto' },
        customer_name:{title:'Cliente'},
        seller:{title:'Vendedor'},
        total_sale:{title:'Venta'},
        work_order:{title:'Orden de Trabajo'},
       },
       csvTitle:`export_notas_venta_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //tomando en cuenta fecha UTC porque la busqueda es en timestamp
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')
      
      //Tomando las opciones de
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      //Bloqueo solo a NV donde el usuario es el vendedor
      if (!this.$can('readAll', 'SalesNote')){
        const user = this.$store.getters.getUser
        this.filter.seller=user.id
      }
      await fetchSalesNote(
        sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`,
        this.search,
        created_after,
        created_before,
        this.filter.customer.id,
        this.filter.seller,
        this.filter.status,
        1,
        -1)
      .then((data)=>{
        this.salesNotes=data.results
        this.salesNotes.map(salesNote=>{
          salesNote.created_at=moment(salesNote.created_at).format("DD/MM/yyyy HH:mm")
          salesNote.customer_name=salesNote.sales_note_customer.name
          salesNote.seller=`${salesNote.sales_note_customer.seller.first_name} ${salesNote.sales_note_customer.seller.last_name}`
          salesNote.total_sale=numeral(salesNote.totals.total).format('0')
          salesNote.work_order=salesNote.work_order?salesNote.work_order:''
        })
        this.dataReady=true
      })
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Notas de Venta Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Notas de Venta')
      this.dataReady=false
    }
  }

}
</script>