<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="workOrders"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchPostCostWorkOrders,} from '@/api/reports'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportPostCost",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      workOrders:[],
      dataReady:false,
      labels:{ 
        id:{title:'Nro'},
        created_at:{title:'Creada'},
        name: { title: 'Proyecto' },
        sales_note:{title:'Cotización'},
        customer_name:{title:'Cliente'},
        sales_total:{title:'Venta'},
        sales_cost:{title:'Costo Teórico'},
        estimated_margin:{title:'Margen Teórico'},
        total:{title:'Costo Real'},
        real_margin:{title:'Margen Real'},
        production_cost:{title:'Costo de Producción'},
        consume_cost:{title:'Costo de Consumo Producción'},
        additional_costs:{title:'Costo Adicional'},
        purchase_orders_costs:{title:'Costo de Ordenes de Compra'},
        warehouse_consumption:{title:'Costo de Consumo de Bodega'},
       },
       csvTitle:`export_postcost_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      var created_before = ''
      var created_after = ''
      if(this.filter.created_after) created_after = moment(this.filter.created_after).utc().format('yyyy-MM-DD HH:mm')
      if(this.filter.created_before) created_before=moment(this.filter.created_before).utc().add(1439,'minutes').format('yyyy-MM-DD HH:mm')

      const {sortBy, sortDesc,page, itemsPerPage }=this.options
       const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=1&created_after=${created_after}&created_before=${created_before}&completed=true`
      await fetchPostCostWorkOrders(query)
      .then((data)=>{
        this.totalWorkOrders=data.count
        this.workOrders = data.results.map(item=>{
          const {additional_costs,
          warehouse_consumption, 
          consume_cost,
          production_cost,
          purchase_orders_costs,
          sales_cost, sales_total, total} = item.real_price
          item.estimated_margin=sales_total!=0?(1-sales_cost/sales_total)*100:0
          item.real_margin=total!=0?(1-sales_cost/total)*100:0
          return {...item,additional_costs, consume_cost, production_cost, purchase_orders_costs, sales_cost, sales_total, total,warehouse_consumption}
        })
        this.dataReady=true
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      .finally(()=>this.loading=false)
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>